<script setup lang="ts">
import type { Button } from "@/types/button";
/*
Custom Button Component

- button (outerClass)
  -loading (spinner)
  - wrapperClass
      - prefixIcon
      - prefix (slot)
      - label
      - suffix (slot)
      - suffixIcon
  - end wrapperClass
- end button
*/

const props = withDefaults(defineProps<Button>(), {
  color: "ngreen",
  type: "primary",
  isSubmit: false,
});

//Set Colors
const color = toRef(props, "color");
const type = toRef(props, "type");
const isSubmit = toRef(props, "isSubmit");

let colorClass = computed(() => {
  if (type.value === "primary") {
    return `bg-${color.value} hover:bg-${color.value} hover:bg-opacity-70 dark:bg-${color.value} dark:hover:bg-${color.value}/70 dark:border-gray-600  hover:border-${color.value}/80`;
  }
  if (type.value === "secondary") {
    return `hover:bg-${color.value} text-black dark:text-white hover:text-black/70 dark:hover:text-white/80 hover:bg-opacity-80 dark:border-gray-600 dark:hover:border-gray-600/60 border-${color.value} hover:border-${color.value}/80`;
  }
  if (type.value === "minimal") {
    return `text-${color.value} hover:text-${color.value}/70 bg-transparent dark:bg-transparent`;
  }
});

//Set Rounded
const rounded = toRef(props, "rounded");
const roundedClass = computed(() => {
  if (rounded.value) {
    return "rounded-full";
  }
});

//Click Loading Function
const loading = toRef(props, "loading");
const hover = ref(false);
const mode = ref("");
const darkColor = "#161B22";
const lightColor = "#ffffff";

if (type.value === "secondary" && !isDark.value) {
  mode.value = darkColor;
} else {
  mode.value = lightColor;
}

watch(
  () => hover.value,
  () => {
    if (hover.value) {
      mode.value = lightColor;
    } else if (!hover.value && !isDark.value) {
      mode.value = darkColor;
    } else {
      mode.value = lightColor;
    }
  },
);

watch(
  () => [isDark.value],
  () => {
    if (type.value === "secondary" && !isDark.value) {
      mode.value = darkColor;
    } else {
      mode.value = lightColor;
    }
  },
);
//End Click Loading Function
</script>

<template>
  <button
    v-if="type === 'primary'"
    class="max-w-fit disabled:cursor-not-allowed relative disabled:opacity-20 disabled:pointer-events-none flex justify-between items-center transition duration-300 ease-in-out px-6 py-[9px] mt-2 min-w-[50px] text-xs font-semibold text-white border-2 mb-2 lg:text-xl whitespace-nowrap"
    :class="`${colorClass} ${roundedClass} ${outerClass}`"
    v-bind="$attrs"
    :type="isSubmit ? 'submit' : 'button'"
  >
    <div class="min-w-[75px]" v-if="loading">
      <Icon name="svg-spinners:ring-resize" class="text-lg dark:text-white" />
    </div>
    <div
      class="z-10 flex items-center justify-center"
      :class="`${wrapperClass}`"
      v-if="!loading"
    >
      <div
        class="mr-2 text-lg font-semibold leading-4"
        :class="`${prefixIconClass}`"
        v-if="prefixIcon"
      >
        <Icon :name="prefixIcon" />
      </div>
      <div :class="`${prefixClass}`">
        <slot name="prefix" />
      </div>
      <div
        class="flex justify-center text-xs font-bold leading-4 align-middle"
        :class="`${labelClass}`"
      >
        {{ label ?? "" }}
        <slot />
      </div>
      <div :class="`${suffixClass}`">
        <slot name="suffix" />
      </div>
      <div
        class="ml-2 text-lg leading-4"
        :class="`${suffixIconClass}`"
        v-if="suffixIcon"
      >
        <Icon :name="suffixIcon" />
      </div>
    </div>
  </button>

  <button
    v-if="type === 'secondary'"
    class="max-w-fit disabled:cursor-not-allowed disabled:opacity-20 dark:bg-transparent bg-transparent relative disabled:pointer-events-none flex justify-between items-center transition duration-300 ease-in-out px-6 py-[9px] mt-2 min-w-[50px] text-xs font-semibold border-2 mb-2 lg:text-xl whitespace-nowrap"
    :class="`${colorClass} ${roundedClass} ${outerClass}`"
    v-bind="$attrs"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :type="isSubmit ? 'submit' : 'button'"
  >
    <div class="min-w-[75px]" v-if="loading">
      <Icon name="svg-spinners:ring-resize" class="text-lg dark:text-white" />
    </div>
    <div
      class="z-10 flex items-center justify-center"
      :class="`${wrapperClass}`"
      v-if="!loading"
    >
      <div
        class="mr-2 text-lg font-semibold leading-4"
        :class="`${prefixIconClass}`"
        v-if="prefixIcon"
      >
        <Icon :name="prefixIcon" />
      </div>
      <div :class="`${prefixClass}`">
        <slot name="prefix" />
      </div>
      <div
        class="flex justify-center text-xs font-bold leading-4 align-middle"
        :class="`${labelClass}`"
      >
        {{ label ?? "" }}
        <slot />
      </div>
      <div :class="`${suffixClass}`">
        <slot name="suffix" />
      </div>
      <div
        class="ml-2 text-lg leading-4"
        :class="`${suffixIconClass}`"
        v-if="suffixIcon"
      >
        <Icon :name="suffixIcon" />
      </div>
    </div>
  </button>
  <button
    v-if="type === 'minimal'"
    class="max-w-fit disabled:cursor-not-allowed disabled:opacity-20 flex justify-between items-center transition duration-300 ease-in-out px-6 mt-2 min-w-[50px] text-xs font-semibold bg-transparent border-0 mb-2 lg:text-xl whitespace-nowrap"
    :class="`${colorClass} ${roundedClass} ${outerClass}`"
    v-bind="$attrs"
    :type="isSubmit ? 'submit' : 'button'"
  >
    <div
      class="z-10 flex items-center justify-center"
      :class="`${wrapperClass}`"
    >
      <Icon
        :name="prefixIcon"
        class="mr-2 text-lg font-semibold leading-4 text-black dark:text-white"
        :class="`${prefixIconClass}`"
        v-if="prefixIcon"
      />
      <div class="text-black dark:text-white" :class="`${prefixClass}`">
        <slot name="prefix" />
      </div>
      <div
        class="flex justify-center text-xs font-bold leading-4 align-middle dark:text-white"
        :class="`${labelClass}`"
      >
        {{ label ?? "" }}
        <slot />
      </div>
      <div class="text-black dark:text-white" :class="`${suffixClass}`">
        <slot name="suffix" />
      </div>
      <Icon
        :name="suffixIcon"
        class="ml-2 text-lg leading-4 text-black dark:text-white"
        :class="`${suffixIconClass}`"
        v-if="suffixIcon"
      />
    </div>
  </button>
</template>
<style>
:root {
  --spinner-offset: 145;
  --spinner-offset-min: 20;
  --spinner-duration: 2400ms;
  --spinner-size: 25px;
}

.spinner {
  display: inline-block;
  width: var(--spinner-size);
  height: var(--spinner-size);
}

.spinner svg {
  animation: rotator var(--spinner-duration) linear infinite;
}

.spinner circle {
  fill: none;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-dasharray: var(--spinner-offset);
  transform-origin: center;
  animation:
    dash var(--spinner-duration) ease-in-out infinite,
    colors calc(var(--spinner-duration) * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: v-bind(mode);
  }
  25% {
    stroke: v-bind(mode);
  }
  50% {
    stroke: v-bind(mode);
  }
  75% {
    stroke: v-bind(mode);
  }
  100% {
    stroke: v-bind(mode);
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: var(--spinner-offset);
  }
  50% {
    stroke-dashoffset: var(--spinner-offset-min);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: var(--spinner-offset);
    transform: rotate(450deg);
  }
}
</style>
